.default {}
.project {
    position: relative;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);

    .title {
        opacity: 0;
        pointer-events: none;
    }
}

.--android .archive,
.--ios .archive,
.--android .project,
.--ios .project {
    background-color: var(--white);
}
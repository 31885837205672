
.project-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (min-width: $smartphone) {
        --offset-x: 10%;
        --offset-y: 10%;
        --width: calc(100% - var(--offset-x) * 2);
        --height: calc(100% - var(--offset-y) * 2);
    }

    @media (max-width: $smartphone) {
        --offset-x: 10%;
        --offset-y: 5%;
        --width: calc(100% - var(--offset-x) * 2);
        --height: calc(100% - var(--offset-y) * 2);
    }
      
    svg,
    video,
    img {
        position: absolute;
        top: var(--offset-y);
        left: var(--offset-x);
        width: var(--width);
        height: var(--height);
        object-fit: contain;
        object-position: center center;
    }

    svg {
       
        rect {
            fill: #f0f0f0;
        }
    }
}

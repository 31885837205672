.block-player-full {
    width: 100vw;
    height: 100vh;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .plaver-video__interface {
        display: none;
    }

    > .title {
        position: absolute;
        top:0;
        left:0;
        pointer-events: none;
        opacity: 0;
    }
}

.block-project {
    @media (min-width: $smartphone) {
        --font-size: var(--font-size-base);
        --font-size-title: var(--font-size-l);
        --width-slider: 15%;
        --padding: var(--padding-m);
        --grid-size: #{rem(272)};
        --grid-template-columns: var(--grid-size) var(--grid-size) auto;
        --grid-gap: #{rem(81)};
        --description-max-width: #{rem(413)};
    }
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-base);
        --font-size-title: var(--font-size-l);
        --width-slider: 15%;
        --padding: var(--padding-s);
        --description-max-width: #{rem(413)};
        --grid-gap: var(--padding-s);
        --grid-size: #{rem(272)};
        --grid-template-columns: auto auto;
    }
}

.block-project {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    column-gap: var(--grid-gap);
    height: 100vh;
    padding: var(--padding);

    @media (max-width: $smartphone) {
        grid-template-rows: auto auto auto 1fr;
    }
    
    h1 {
        @media (min-width: $smartphone) {
            position: absolute;
            top: 0;
            left: 0;
            filter: blur(rem(.8));
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: $smartphone) {
            position: relative;
            grid-column: 1 / 3;
            grid-row: 3 / 4;
            padding-top: .63em;
        }

        pointer-events: none;
        width: 100%;
        height: 100%;
        @include font-sans-bold(1);
        font-size: var(--font-size-title);
        text-align: center;
        text-transform: uppercase;
        
    }
}

.block-project__title {
    display: flex;
    justify-content: center;
    opacity: 0;

    > span {
        display: block;
        margin: 0 .078em;
        overflow: hidden;
        height: 1em;
    }

    div {
        display: inline-block;
        overflow: hidden;
        display: inline-block;
        width: auto;
        height: 1em;

        > span {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
            
            > span {
                display: block;
                position: relative;
                white-space: nowrap;
            }
        }
    }
}

.block-project__info  {
    @include font-sans();

    &.--credits {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    &.--technical-data {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }

    &.--description {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        max-width: var(--description-max-width);

        @media (min-width: $smartphone) {
            align-self: flex-end;
        }
    }
            
    h2, h3, h4, h5, b {
        color: var(--secondary-color);
        font-size: var(--font-size-base);
        margin-top: .7em;
    }

    div, p, ul, ol {
        color: var(--primary-color);
        font-size: var(--font-size-base);
    }

    a {
        --scale: 0;
        --origin: 100%;

        .__mouse-right & { --origin: 100%; }
        .__mouse-left & { --origin: 0; }

        position: relative;
        display: inline-block;
        pointer-events: all;
        -webkit-user-drag: none;
        @include basic-a();
               
        color: var(--color);
        
        ::selection {
            color: var(--primary-bg);
            background: var(--primary-color);
        }

        user-select: none;
        &[href^='mailto'] {
            user-select: auto;
        }

        &::before {
            position: absolute;
            left: 0;
            bottom: -.1em;
            content: '';
            width: calc(100%);
            border-bottom: rem(1) solid currentColor;
            transform: scale3d(var(--scale), 1, 1);
            transition: transform .4s var(--ease-out-quint);
            transform-origin: var(--origin);
        }

        &:hover {
            --scale: 1;
            --origin: 0;
            .__mouse-right & { --origin: 0; }
            .__mouse-left & { --origin: 100%; }
        }
    }
}

.block-project__close {
    @media (min-width: $smartphone) {
        display: none;
    }

    @media (max-width: $smartphone) {
        @include basic-a();
        grid-column: 1 / 3;
        grid-row: 4 / 5;
        text-align: center;
        @include font-sans();
        color: var(--primary-color);
        font-size: var(--font-size-base);
        margin-top: .7em;
    }
}

.block-project__slider {
    --scale:1;

    position: absolute;
    
    transform-origin: 100% 50%;

    @media (min-width: $smartphone) {
        top: 0;
        right: 0;
        height: 100vh;
        width: var(--width-slider);

        ul {
            width: 100%;
            height: 100%;
            transform: translate3D(150%,0,0);
        }
    }

    @media (max-width: $smartphone) {
        bottom: 0;
        right: 0;
        height: auto;
        width: 100%;
        overflow: hidden;
    }

    ul {
        position: relative;
    }

    li {
        &:first-of-type {
            position: relative;
            opacity: 0;
            pointer-events: none;
        }
        
        top: 0;
        width: 100%;
        height: auto;

        @media (min-width: $smartphone) {
            width: 100%;
        }

        @media (max-width: $smartphone) {
            width: 60%;
        }
    }

    figure {
        overflow: hidden;
    }

    video {
        object-fit: cover !important;
        object-position: center center;
    }

    video,
    img {
        transform: scale3d(var(--scale),var(--scale),1);
        transform-origin: 20% center;
    }
}
.module-slider-horizontal {
    --height: 30vw; 
    --scale:.1;

    position: absolute;
    top: 0;
    right: 0;
    height: var(--height);
    width: 100%;
    transform-origin: 0% 0%;
    transform: scale3d(var(--scale), var(--scale), var(--scale));
    
    ul {
        position: relative;
        width: 100%;
        height: 100%;
    }

    li {
        width: auto;
        height: 100%;

        a {
            @include basic-a();
            display: block;
            width: auto;
            height: 100%;
        }
    }

    figure {
        position: relative;
        width: auto;
        height: 100%;
        overflow: hidden;

        svg {
            height: 100%;
            width: auto;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
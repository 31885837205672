@use "sass:math";

.block-legal,
.block-wysiwyg {
    --font-size-h1: var(--font-size-base);
    --font-size-h2: var(--font-size-base);
    --font-size-h3: var(--font-size-base);
    --font-size: var(--font-size-base);
    --color: var(--primary-color);

    --max-width: #{math.div(1500px, 16px) * 1rem};
    --margin-v: 0;
    --padding-v: 10vh;
    --padding-h: 25%;

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }  
}

.block-legal {
    min-height: 100vh;
}

.block-legal,
.block-wysiwyg {
    color: var(--primary-color);

    font-size: var(--font-size);
    max-width: var(--max-width);
    margin: var(--margin-v) auto;
    padding: var(--padding-v) var(--padding-h);
    @include font-sans-bold();

    h1 {
        @include font-sans-bold(1);
        font-size: var(--font-size-h1);
        text-transform: uppercase;
        margin: .5em 0;
    }

    h2 {
        @include font-sans-bold(1);
        font-size: var(--font-size-h2);
        text-transform: uppercase;
        margin: .5em 0;
    }

    h3 {
        @include font-sans-bold(1);
        font-size: var(--font-size-h3);
        margin: .5em 0;
    }

    svg {
        max-width: 100px;
        height: auto;
    }

    p {
        i {
            @include font-sans-bold();
        }

        strong,
        b {
            @include font-sans-bold();
        }
        margin: .5em 0;
    }

    ul, ol {
        margin: 2em 0;
        padding-left: 4em;
    }

    a {
        @include basic-a();
        @include font-sans-bold();
        text-decoration: underline;
        color: inherit;
    }
}


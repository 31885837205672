@use "sass:math";

:root {
  --y-header: 0;

  --padding-xl: #{math.div(100px, 16px) * 1rem};
  --padding-l: #{math.div(40px, 16px) * 1rem};
  --padding-m: #{math.div(20px, 16px) * 1rem};
  --padding-s: #{math.div(10px, 16px) * 1rem};
  --padding-xs: #{math.div(5px, 16px) * 1rem};
}

@media (max-width: $smartphone) {
  :root {}
}

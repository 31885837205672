#full-slider {
  --heigth-item: 100%;

  @media (min-width: $smartphone) {
    --width-item: 50vw;
  }
  @media (max-width: $smartphone) {
    --width-item: 90vw;
  }
}

#full-slider {
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  
  ul {
    position: relative;
    width: 100%;
    height: 100%;
  }

  li {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: var(--width-item);
      height: var(--heigth-item);
  }
}

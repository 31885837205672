@use "sass:math";

:root {
    --font-sans: 'Neue Haas Grotesk Display Pro', sans-serif;
    --font-sans-bold: 'Neue Haas Grotesk Display Pro', sans-serif;

    --font-size-xl: #{math.div(48px, 16px) * 1rem};
    --font-size-l: #{math.div(36px, 16px) * 1rem};
    --font-size-m: #{math.div(14px, 16px) * 1rem};
    --font-size-base: #{math.div(12px, 16px) * 1rem};
    --font-size-s: #{math.div(10px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: 1.05;
    --letter-spacing: 0.01em;

    --min-font-size: 14px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: 600;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height:0) {
    font-family: var(--font-sans-bold);
    font-weight: 700;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height:0) {
    font-family: var(--font-sans);
    font-weight: 600;
    font-style: italic;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

:root {
    --white: #ffffff;
    --black: #000000;
    --red: #FF0000
    --grey: #666666;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #FF0000;
    --focus: #FF0000;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}
.block-project-list {
    --color: var(--primary-color);
    --padding: var(--padding-l);
    --font-size: var(--font-size-base);
    --grid-template: repeat(6, 16.666%);
    --padding-general: var(--padding-m);

    @media (max-width: $desktop) {
        --padding: var(--padding-xs);
        //--grid-template: repeat(6, 25%);
    }

    @media (max-width: $tabletLandscape) {
        --padding: var(--padding-s);
        --grid-template: repeat(3, 33.333%);
    }

    @media (max-width: $smartphone) {
        --padding: var(--padding-l);
        --font-size: 0px;
        --grid-template: repeat(1, 100%);
        --padding-general: var(--padding-l); //3.6em;
    }
}

.block-project-list {
    min-height: 10vh;
    color: var(--color);

    ul {
        padding: var(--padding-general) 0 0;

        @media (min-width: $smartphone) {
            display: grid;
            grid-template-columns: var(--grid-template);

            li {
                padding-top: 100%;

                > div {
                    @include font-sans(1);
                    font-size: var(--font-size);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left:0;
                    padding: var(--padding) var(--padding) calc(var(--padding) - 2em);
                }

                a {
                    --scale:1;
                    --time: .4s;
                    --ease: var( --ease-in-out-quad);

                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    top:0;
                    left:0;
                    
                    span {
                        flex: 0 0 2em;
                        padding-top: 1em;
                        display: block;
                        width: 100%;
                        text-align: center;
                    }


                    &:hover {
                        --scale:.95;
                        --time: .2s;
                        --ease: var( --ease-out-quad);
                    }
                }
            }
            
            figure {
                padding-top: 0;
                width: 100%;
                height: 100%;

                svg {
                    position: absolute;
                    top:1%;
                    left:1%;
                    width: 98%;
                    height: 98%;
                    object-fit: contain;

                    rect {
                        fill: #f0f0f0;
                    }
                }
            }
        }

        @media (max-width: $smartphone) {
            display: grid;
            grid-template-columns: 1fr;
            gap: var(--padding);

            li {
                padding: 0 var(--padding);
            }

            li a > span {
                display: none;
            }
        }

        li {
            position: relative;
            
            a {
                @include basic-a();
                color: var(--color);
                position: relative;
            }

            video,
            img {
                position: relative;
                flex: 1 0 auto;
                transform: scale3d(var(--scale), var(--scale), var(--scale));
                transition: transform var(--time) var( --ease);
                object-fit: contain;
                object-position: center center;
                width: 100%;
                height: 100%;
            }

            svg {
                transform: scale3d(var(--scale), var(--scale), var(--scale));
                transition: transform var(--time) var( --ease);

                @media (max-width: $smartphone) {
                    position: absolute;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
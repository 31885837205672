.widget-about {
    @media (min-width: $smartphone) {
        --font-size-title: 50vw;
        --font-size: var(--font-size-l);
        --padding: var(--padding-m);
        --cols: 1fr 1fr; 
        --offset-top-title: 0;
    }

    @media (max-width: 900px) {
        --font-size: #{rem(24)};
    }

    @media (max-width: $smartphone) {
        --font-size-title: 50vw;
        --font-size: #{rem(18)};
        --padding: var(--padding-s) var(--padding-s);
        --cols: 1fr;
        --offset-top-title: .3em;
    }
}
.widget-about {
    color: var(--primary-color);
    width: 100vw;
    height: 100vh;
    padding: var(--padding);

    > .title {
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        padding-top: var(--offset-top-title);
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-sans(1);
        font-size: var(--font-size-title);
        color: var(--black);
    }
}

.widget-about .summary {
    position: relative;
    @include font-sans-bold();
    font-size: var(--font-size);
    display: grid;
    grid-template-columns: var(--cols);
    gap: var(--padding-m);
}
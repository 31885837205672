@use "sass:math";

#Footer {
    --color: var(--primary-color);

    @media (min-width: $smartphone) {
        --font-size-title: 51vw;
        --font-size: var(--font-size-l);
        --font-size-legal: var(--font-size-base);
        --padding: var(--padding-m);
        --margin: var(--padding-xl) 0 0;
        --height-logo: #{rem(30)};
        --height-logo2: #{rem(28)};
    }

    @media (max-width: 900px) {
        --font-size: #{rem(24)};
    }

    @media (max-width: $smartphone) {
        --font-size-title: 50vw;
        --font-size: var(--font-size-base);
        --font-size-legal: var(--font-size-s);
        --padding: var(--padding-l) var(--padding-s) var(--padding-s);
        --margin: 0 0 0;
        --height-logo: #{rem(30)};
        --height-logo2: #{rem(24)};
    }
}

.--remove-footer #Footer {
    display: none;
}

.--ios #Footer {
    background-color: var(--primary-bg);
}

#Footer {
    padding: var(--padding);
    margin: var(--margin);
    display: flex;
    flex-wrap: wrap;
}

#Footer > .title {
    flex: 1 0 100%;
    width: 100%;
    @include font-sans(1);
    font-size: var(--font-size-title);
    color: var(--color);
    margin-left: -.5vw;
    margin-bottom: -2vw;
    padding-top: .02em;
}

#Footer .summary {
    flex: 1 0 100%;
    width: 100%;
    position: relative;
    @include font-sans-bold();
    color: var(--color);
    font-size: var(--font-size);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--padding-m);
}

#Footer .top {
    flex: 0 0 auto;
    @include font-sans();
    font-size: var(--font-size-legal);
    display: none;
    justify-content: flex-start;
    align-items: center;

    .footer-links {
        flex: 0 0 auto;
        width: auto;
        display: inline-grid;
        grid-template-rows: auto auto;
        grid-auto-flow: column;
        column-gap: .5em;

        span {
            display: inline-block;
            padding: .1em;
            text-align: left;
            width: auto;
            color: var(--color);
        }

        a {
            @include basic-a();
            padding: .1em;
            background-color: transparent;
            text-align: left;
            width: auto;
            color: var(--color);
        }
    }
}

#Footer .bottom {
    flex: 1 0 50%;
    @include font-sans();
    font-size: var(--font-size-legal);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    column-gap: .5em;

    .legales {
        flex: 0 0 auto;
        width: auto;
        display: inline-grid;
        grid-template-columns: auto auto;
        column-gap: .5em;

        @media (max-width: $smartphone) {
            grid-template-columns: auto;
        }

        button,
        a {
            @include basic-a();
            padding: .1em;
            background-color: transparent;
            text-align: left;
            width: auto;
            color: var(--color);
        }
    }

    .kitdigital {
        display: flex;
        justify-content: flex-start;
        flex: 0 0 auto;
        width: auto;

        @media (max-width: $smartphone) {
            align-self: flex-end;
            justify-content: flex-end;
            align-items: center;
        }

        a {
            @include basic-a();
            display: inline-block;
            height: var(--height-logo);
            padding: 0 .7em .3em 1em;

            > svg {
                height: 100%;
                width: auto;
            }
        }

        > svg {
            height: var(--height-logo2);
            width: auto;
        }

        svg {
            fill: var(--color);
        }
    }
}

@use "sass:math";

#Sidemenu {
    display: none;
}

#Header {
    --opacity: 1;
    --width: 100vw;
    --height: 100vh;
    --gap: var(--padding-xs);
    --color: var(--white);
    --display: flex;
    --font-size: var(--font-size-base);
    --padding: var(--font-size-m);
    --padding-links: 1em;

    @media (max-width: $smartphone) {
        --display: none; 
    }
}

#HeaderMobile {
    --opacity: 1;
    --width: 100vw;
    --height: 100vh;
    --gap: var(--padding-xs);
    --color: var(--white);
    --display: flex;
    --font-size: var(--font-size-base);
    --padding: var(--font-size-s);   
    --padding-links: .5em; 

    @media (min-width: $smartphone) {
        --display: none;
    }

    opacity: 1 !important;
}

#HeaderMobile {
    font-size: var(--font-size);
    flex-wrap: wrap;
    justify-content: flex-start !important;
    align-items: center;
    flex-direction: column;
    padding: .8em 0;

    > div {
        width: 100%;
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header__holder {
        position: absolute;
        left: 0;
        width: 100%;

        &:nth-child(1) { top: calc(50% - 1em); }
        &:nth-child(2) { top: calc(50% + 1em); }
        &:nth-child(3) { top: .8em; }
        &:nth-child(4) { top: calc(50% + 1em); }
    }

    .header_video {
        position: absolute;
        top: auto;
        bottom: .8em;
        left: 0;
    }

    .header_controls {
        position: absolute;
        top: .8em;
        bottom: auto;
        left: 0;
    }
}

#HeaderMobile,
#Header {
    position: fixed;
    @include z-index($z-index-header);
    pointer-events: none;
    display: var(--display);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: var(--opacity);

    &:hover {
        opacity: 1;
    }

    &.--invert {
        mix-blend-mode: difference;
    }

    &.--black {
        --color: var(--black);
        text-shadow: 
        rgb(255, 255, 255) .05em .05em 0px;
    }

    button,
    a {
        --scale: 0;
        --origin: 100%;

        .__mouse-right & { --origin: 100%; }
        .__mouse-left & { --origin: 0; }

        position: relative;
        background-color: transparent;
        height: calc(1em + var(--padding-links) * 2);
        display: block;
        pointer-events: all;
        -webkit-user-drag: none;
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size);
        color: var(--color);
        overflow: hidden;
        padding: var(--padding-links) 0;

        ::selection {
            color: var(--primary-bg);
            background: var(--primary-color);
        }

        span.timer {
            font-family: "Neue Haas Grotesk Display Mono", monospace;
            font-weight: 600;
            font-style: normal;
            transform: translateY(-.05em);
        }

        user-select: none;
        &[href^='mailto'] {
            user-select: auto;
        }

        &.--disabled {
            pointer-events: none;
        }

        > div {
            overflow: hidden;
            display: inline-block;
            width: auto;
            height: 1em;

            > span {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                overflow: hidden;

                /*.palette-secondary & {
                    background-color: var(--primary-bg);
                }*/
                
                > span {
                    display: block;
                    position: relative;
                    white-space: nowrap;
                    height: 1em;
                    padding: 0 var(--gap);
                }
            }
        }

        &:first-of-type {
            
            > span {
                display: block;
                position: relative;
            }
            > div > span > span {
                padding-right: calc(var(--gap)*.25);
            }
        }

        &:not(:first-of-type) {
            > span {
                position: absolute;
                top: 1em;
                left: 0;
            }
        }

        &::before {
            position: absolute;
            left: var(--gap);
            bottom: calc(var(--padding-links) * .9);
            content: '';
            width: calc(100% - var(--gap) * 2);
            border-bottom: rem(1) solid currentColor;
            transform: scale3d(var(--scale), 1, 1);
            transition: transform .4s var(--ease-out-quint);
            transform-origin: var(--origin);
        }

        &:not(:first-of-type)[aria-current="page"],
        &:hover {
            --scale: 1;
            --origin: 0;
            .__mouse-right & { --origin: 0; }
            .__mouse-left & { --origin: 100%; }
        }

        &[aria-current="page"] {
            pointer-events: none;
        }
    }
}

#HeaderMobile {
    .header__holder:not(:first-of-type) {
        a:first-of-type[aria-current="page"],
        &:hover {
            --scale: 1;
            --origin: 0;
            .__mouse-right & { --origin: 0; }
            .__mouse-left & { --origin: 100%; }
        }
    }
}

.--legal #HeaderMobile .header__holder:nth-child(1) {
    top: .8em;
}

//PLAYER

#HeaderMobile,
#Header {
    [data-mute] {
        .muted { display: none; }
        .unmuted { display: inline-block; }
    }

    .--muted & {
        [data-mute] {
            .muted { display: none; }
            .unmuted { display: inline-block; }
        }
    }

    .--unmuted & {
        [data-mute] {
            .muted { display: inline-block; }
            .unmuted { display: none; }
        }
    }

    .--play & {
        [data-play] {
            --scale: 1;
            --origin: 0;
            pointer-events: none;
        }
    }

    .--pause & {
        [data-pause] {
            --scale: 1;
            --origin: 0;
            pointer-events: none;
        }
    }
}

.yza-voku-deco {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-sans(1);
    color: white;

    @media (min-width: $smartphone) {
        font-size: 50vw;
    }

    @media (max-width: $smartphone) {
        font-size: 50vw;
    }
}


#Header > #HeaderClose {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--padding);
}
#circle-slider {
  --width-item: #{rem(26)};
  --heigth-item: #{rem(39)};

  @media (max-width: $smartphone) {
    --width-item: calc(26px * .6);
    --heigth-item: calc(39px * .6);
  }
}

#circle-slider {
  position: fixed;
  top:0;
  left:0;
  opacity: 0;
  pointer-events: none;

  li {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: var(--width-item);
    height: var(--heigth-item);
  }
}
